'use client';
import React, { useEffect, useState, useRef } from 'react';
import Helmet from 'react-helmet';
import Popup from '../components/Popup';
import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import Image from '../components/image';
import ImageGrid from '../components/imageGrid';
import { useForm } from 'react-hook-form';
import { Logos, Numbers, BrandsYouTrust } from '../components/SocialProof';
import { PremiumSample } from '../components/explore';
import { GetStarted, adVariations } from '../components/CTA';
import { getMatchedType } from '../components/TypeTag';
import posthog from 'posthog-js';
import * as z from 'zod';
import { useToast } from '../components/ui/use-toast';
import { auth, googleProvider } from '../components/firebase';
import { signInWithPopup } from 'firebase/auth';
import GetStartedFormContent from '../components/CTA';

import {
 Accordion,
 AccordionContent,
 AccordionItem,
 AccordionTrigger,
} from '../components/ui/accordion';

import { Button } from '../components/ui/button';
import {
 Form,
 FormControl,
 FormDescription,
 FormField,
 FormItem,
 FormLabel,
 FormMessage,
} from '../components/ui/form';
import { Input } from '../components/ui/input';

import {
 Sheet,
 SheetContent,
 SheetDescription,
 SheetHeader,
 SheetTitle,
 SheetTrigger,
} from '../components/ui/sheet';
import { ScrollArea } from '../components/ui/scroll-area';

declare global {
 interface Window {
  dataLayer?: any[];
 }
}

const UTMCampaign: React.FC = () => {
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const slug = searchParams.get('search_term');
 let type_name = searchParams.get('type');

 let name = '';
 let type_id = null;

 if (slug) {
  name = slug.replace(/-/g, ' ');
 } else if (type_name) {
  type_name = type_name.replace(/-/g, ' ');
  const matchedType = getMatchedType(type_name);
  if (matchedType) {
   name = matchedType.output;
   type_id = matchedType.typeId;
  }
 }

 return (
  <>
   {name && (
    <div className="max-w-screen-xl mx-auto">
     <PremiumSample
      policy_slug={slug}
      type_id={type_id}
      readMore={false}
      cta_text="Get your own suggestions"
      cta_link="#cta"
      header_className="primary-h2 text-center capitalize"
      header={`Real suggestions advisors provided for ${name}`}
     />
    </div>
   )}
  </>
 );
};

const formSchema = z.object({
 alias: z.string().min(2, {
  message: 'Alias must be at least 2 characters.',
 }),
 email: z.string().email({
  message: 'Please enter a valid email address.',
 }),
});

interface PremiumsOnUsFAQProps {
 triggerText: string;
 className?: string;
}

interface DownloadResponse {
 downloadLink: string;
 fileName: string;
}

export const DownloadFiles: React.FC = () => {
 const { fileType } = useParams<{ fileType: string }>();
 const navigate = useNavigate();
 const { toast } = useToast();
 const [isLoading, setIsLoading] = useState(true);
 const [downloadLink, setDownloadLink] = useState<string | null>(null);

 useEffect(() => {
  const fetchDownloadLink = async () => {
   try {
    const response = await fetch(`/api/download/${fileType}`);
    if (!response.ok) {
     throw new Error('Failed to fetch download link');
    }
    const data: DownloadResponse = await response.json();
    if (data.downloadLink) {
     setIsLoading(false);
     setDownloadLink(data.downloadLink);
     // Open the download link in a new tab
     window.open(data.downloadLink, '_blank');
    } else {
     throw new Error('Download link not found');
    }
   } catch (error) {
    console.error('Error fetching download link:', error);
    toast({
     variant: 'destructive',
     title: 'Download Error',
     description: `Failed to download the ${fileType}. Please try again later.`,
    });
    navigate('/');
   }
  };

  if (fileType) {
   fetchDownloadLink();
  } else {
   toast({
    variant: 'destructive',
    title: 'Invalid Request',
    description: 'Invalid download request.',
   });
   navigate('/');
  }
 }, [fileType, navigate, toast]);

 return (
  <div className="flex items-center justify-center h-screen bg-white-1">
   {isLoading ? (
    <p className="text-xl primary-h2">Preparing your {fileType} download...</p>
   ) : (
    <p className="text-xl">
     Your download should begin shortly. If it doesn't,{' '}
     <a
      href={downloadLink || '#'}
      target="_blank"
      rel="noopener noreferrer"
      className="primary-link"
     >
      click here to download directly
     </a>
     .
    </p>
   )}
  </div>
 );
};

export function PremiumsOnUsFAQ({
 triggerText,
 className,
}: PremiumsOnUsFAQProps) {
 return (
  <Sheet>
   <SheetTrigger>
    <p className={className ? className : 'underline text-green-1'}>
     {triggerText}
    </p>
   </SheetTrigger>
   <SheetContent side="bottom" className="bg-white-1">
    <SheetHeader>
     <SheetTitle>
      <p className="text-center text-green-1 font-semibold">
       Premiums on Us FAQ
      </p>
     </SheetTitle>
     <SheetDescription>
      <div className="w-full p-4 text-start mx-auto">
       <ScrollArea className="h-96 max-w-xl md:mx-auto pr-2" type="always">
        <div className="*:py-1">
         <p>
          Our quarterly insurance giveaway is an opportunity to{' '}
          <b>get 90 days of your premiums paid</b> for by simply getting the
          insurance you need. Here's exactly how it works:
         </p>
         <p>
          You can earn entries by signing up on our platform and connecting with
          the 5 diverse advisors we've randomly selected. Have an intro call
          with any of those vetted advisor matches? Bingo - that's one entry for
          a chance at premium freedom!{' '}
          <b>
           However, you can only earn up to 3 entries maximum from advisor
           conversations.
          </b>
         </p>
         <p>
          But listen closely: Those <b>entries never expire</b>, rolling over
          infinitely from quarter to quarter even if you finally hit the
          jackpot.
         </p>
        </div>
        <Accordion type="single" collapsible className="w-full">
         <AccordionItem value="item-1">
          <AccordionTrigger>
           What is the "Premium on Us" campaign?
          </AccordionTrigger>
          <AccordionContent>
           The "Premiums On Us" campaign is a quarterly giveaway where one
           Whatins member will have their monthly insurance premium paid for by
           Whatins for an entire 30-day period.
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-2">
          <AccordionTrigger>
           How do I enter the giveaway and what qualifies for the prize?
          </AccordionTrigger>
          <AccordionContent>
           <p>
            To enter, you must sign up for Whatins and complete the initial
            questionnaire. Here's how it works:
           </p>
           <ul>
            <li>
             For each advisor you connect with and have an introductory
             conversation with (up to a maximum of 3 advisors), you will earn
             one entry into the quarterly giveaway drawing.
            </li>
            <li>Your entries never expire and roll over to future drawings.</li>
            <li>
             To qualify for the prize, you must purchase an initial policy
             through one of the advisors from your customized match list.
            </li>
           </ul>
           <p>Here's an example:</p>
           <ul>
            <li>Let's say you match with advisors A, B, C, D and E.</li>
            <li>
             You chat with A, B, and C, earning 3 entries for the quarter.
            </li>
            <li>Then you buy a policy through advisor B.</li>
            <li>
             <b>You now have 3 entries to Premiums On Us</b>
            </li>
           </ul>
           <p>
            Important: If you purchase a policy from an advisor not on your
            recommended list, it wouldn't count towards the giveaway prize.
           </p>
           <p>
            The more you engage with the advisors we've curated for you, the
            more chances you have to win a quarter of free insurance premiums.
            Don't miss this opportunity to get quality coverage at a reduced
            cost!
           </p>
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-3">
          <AccordionTrigger>Do my entries expire?</AccordionTrigger>
          <AccordionContent>
           No, your entries do not expire. Any entries you earn will roll over
           indefinitely from one quarterly drawing to the next.
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-4">
          <AccordionTrigger>What do I win?</AccordionTrigger>
          <AccordionContent>
           If you are selected as the winner, Whatins will pay for your monthly
           insurance premium for the 90-day period prior the drawing. This
           applies to all eligible insurance policies you purchased through the
           Whatins platform.
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-5">
          <AccordionTrigger>How are winners selected?</AccordionTrigger>
          <AccordionContent>
           Winners will be randomly selected from all valid entries received
           prior to the quarterly drawing date. The drawing will take place at
           the beginning of each calendar quarter.
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-6">
          <AccordionTrigger>How will I know if I've won?</AccordionTrigger>
          <AccordionContent>
           Whatins will notify the winner directly via email and/or phone within
           7 business days of the quarterly drawing date. The winner's name may
           also be publicly announced on the Whatins website and social media
           channels.
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-7">
          <AccordionTrigger>
           Do I need to make a purchase to enter?
          </AccordionTrigger>
          <AccordionContent>
           No purchase is necessary to enter the "Premiums On Us" giveaway.
           However, in order to claim the prize, the winner must have purchased
           an eligible insurance policy through the Whatins platform.
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-8">
          <AccordionTrigger>
           Can I transfer my prize to someone else?
          </AccordionTrigger>
          <AccordionContent>
           No, the "Premiums On Us" prize is non-transferable and cannot be
           redeemed for cash or any other compensation.
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-9">
          <AccordionTrigger>
           Is there a limit to how many times I can enter?
          </AccordionTrigger>
          <AccordionContent>
           There is no limit to the number of times you can enter the "Premiums
           On Us" giveaway. Your entries will continue to roll over
           indefinitely.
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-10">
          <AccordionTrigger>What if I have more questions?</AccordionTrigger>
          <AccordionContent>
           If you have any other questions about the "Premiums On Us" campaign,
           please reach out to the Whatins customer support team at
           support@whatins.sg.
          </AccordionContent>
         </AccordionItem>
        </Accordion>
       </ScrollArea>
      </div>
     </SheetDescription>
    </SheetHeader>
   </SheetContent>
  </Sheet>
 );
}

export function PlainForm({
 timeoutRef,
}: {
 timeoutRef?: React.RefObject<NodeJS.Timeout | null>;
}) {
 return (
  <div>
   <GetStartedFormContent
    sidebarLayout={false}
    cta="Start 5 minute questionnaire →"
   />
  </div>
 );
}

const AdvertisingLP = () => {
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const variationIndex = parseInt(searchParams.get('variation') || '0', 10);
 const slug = searchParams.get('type');
 const { toast } = useToast();
 const timeoutRef = useRef(null);

 useEffect(() => {
  let socialProofDisplayed = false;

  async function fetchAndDisplaySocialProof() {
   if (socialProofDisplayed) {
    return; // If the function has already been called, exit early
   }
   socialProofDisplayed = true;

   try {
    const response = await fetch('/api/social-proof');
    if (!response.ok) {
     throw new Error('Network response was not ok');
    }
    const messages = await response.json();
    let index = 0;

    const displayNextMessage = () => {
     if (index < messages.length) {
      const message = messages[index];
      toast({
       description: message,
      });
      index++;
      timeoutRef.current = setTimeout(displayNextMessage, 15000); // Store the timeout reference
     }
    };

    displayNextMessage(); // Start displaying messages
   } catch (error) {
    console.error('Failed to fetch social proof messages:', error);
   }
  }

  fetchAndDisplaySocialProof();

  // Clean up the timeout when the component unmounts or the navigation occurs
  return () => {
   if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
   }
  };
 }, []);

 let variation = 'original';
 if (slug) {
  variation = slug.replace(/-/g, ' ');
 }
 const { header, subhead } = adVariations[variation];

 // let header = "The Better Way of Finding The Proper Policy For You";
 // let subhead = "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>.";

 // if (headerVariations[variationIndex]) {
 //   header = headerVariations[variationIndex].header;
 //   subhead = headerVariations[variationIndex].subhead;
 // }

 return (
  <div className="bg-white-1">
   <Helmet>
    <title>Whatins</title>
    <meta
     name="description"
     content="Get personalised advice from 5 insurance experts hassle-free all without sharing your phone number. Start your 5-minute questionnaire now!"
    />
    <meta property="og:title" content="Whatins" />
    <meta
     property="og:description"
     content="Get personalised advice from 5 insurance experts hassle-free all without sharing your phone number. Start your 5-minute questionnaire now!"
    />
    <meta property="og:image" content="/images/whatins.png" />
    <meta property="og:url" content="https://www.whatins.sg" />
    <link rel="canonical" href={`https://www.whatins.sg/lp/get-proposals`} />
   </Helmet>
   <div className="grid py-8 px-4 mx-auto max-w-screen-xl lg:gap-12 xl:gap-0 lg:pt-16 lg:grid-cols-12">
    <div className="place-self-center mr-auto mb-10 lg:col-span-7 xl:col-span-7 xl:mb-0">
     <h1
      className="primary-h1 mb-4 text-4xl font-extrabold tracking-tight leading-snug md:text-5xl xl:text-6xl dark:text-white normal-case"
      dangerouslySetInnerHTML={{ __html: header }}
     ></h1>
     <p
      id="cta"
      className="mb-6 max-w-2xl font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400 pt-4"
      dangerouslySetInnerHTML={{ __html: subhead }}
     ></p>

     <div>
      <PlainForm timeoutRef={timeoutRef} />
     </div>
     <div className="pt-4 md:pt-0">
      <Numbers />
     </div>
    </div>
    <div className="justify-center p-4 max-w-screen-s lg:mt-0 lg:col-span-5 xl:col-span-5 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
     <Image
      src="get_5_proposals.svg"
      alt="what insurance is for you?"
      imgClass="w-full"
     />
    </div>
   </div>
   <div className="flex flex-col py-4">
    <BrandsYouTrust url={false} />
   </div>

   <div className="flex flex-col max-2-screen-2xl mx-auto px-4 md:px-0 bg-green-1 py-4">
    <div
     id="forMembers"
     className="flex flex-col max-w-screen-xl mx-auto py-3 gap-12"
    >
     <div className="flex flex-col md:flex-row gap-16">
      <div className="flex flex-col md:basis-1/3 items-start">
       <Image
        src="whatins-member-1.svg"
        alt="5"
        imgClass="aspect-square"
        className="aspect-square"
       />
       <p className="primary-h3 text-h4">No fuss, no hassle</p>
       <p className="text-white-1">
        Advisors can not spam you as we do not ask for your phone number and do
        not give your email until after they submit their suggestions. This
        allows you to take your time deciding on the right policies for you.
       </p>
      </div>
      <div className="flex flex-col md:basis-1/3 items-start">
       <Image
        src="premiums-on-us.svg"
        alt="5"
        imgClass="aspect-square"
        className="aspect-square"
       />
       <p className="primary-h3 text-h4">Premiums On Us</p>
       <p className="text-white-1">
        Sign up to get matched with advisors and you'll be entered into our
        recurring giveaway. You could win 90 days of premium-free coverage every
        quarter!{' '}
        <a
         className="primary-link text-white-1 hover:text-green-2"
         href="#premium-on-us"
        >
         Learn more
        </a>
       </p>
      </div>
      <div className="flex flex-col md:basis-1/3 items-start">
       <Image
        src="whatins-member-3.svg"
        alt="5"
        imgClass="items-center aspect-square"
        className="aspect-square"
       />
       <p className="primary-h3 text-h4">Don't overpay</p>
       <p className="text-white-1">
        By getting 5 different advisors to share their thoughts, you can
        properly compare your options instead and instantly identify the
        advisors who are trying to oversell you.
       </p>
      </div>
     </div>
     <a
      href="/lp/get-proposals?source=home"
      className="primary-button1 text-center mx-auto"
     >
      Get started →
     </a>
    </div>
   </div>
   <section>
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
     <div className="mx-auto max-w-screen-md text-center">
      <h2 className="primary-h2 py-2 ">Hear what others are saying</h2>
     </div>
     <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <figure className="flex flex-col p-6 bg-white-2 rounded">
       <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white text-pretty">
         Phone number really isn't needed!
        </h3>
        <p className="my-4">
         "I loved that I didn't need to give out my phone number to get great
         advice. They craeted a telegram bot that connects the advisors to me so
         the advisors don't get my personal ID”
        </p>
       </blockquote>
       <figcaption className="flex items-center space-x-3 mt-auto">
        <Image src="generic/Female 10.svg" className="w-16 h-16 rounded-full" />
        <div className="space-y-0.5 font-medium dark:text-white justity-start">
         <div>Lynn</div>
         <div>26</div>
        </div>
       </figcaption>
      </figure>
      <figure className="flex flex-col p-6 bg-white-2 rounded">
       <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
         Great experience
        </h3>
        <p className="my-4">
         "I have some underlying conditions and I was amazed at how the advisors
         were able to give me very helpful suggestions."
        </p>
       </blockquote>
       <figcaption className="flex items-center space-x-3 mt-auto">
        <Image src="generic/Male 12.svg" className="w-16 h-16 rounded-full" />
        <div className="space-y-0.5 font-medium dark:text-white">
         <div>Joseph</div>
         <div>35</div>
        </div>
       </figcaption>
      </figure>
      <figure className="flex flex-col p-6 bg-white-2 rounded">
       <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
         Would recommend
        </h3>
        <p className="my-4">
         "I appreciate this service because I can instantly see when an advisor
         tries to sell me things I don't need as their premiums are much higher
         than the rest."
        </p>
       </blockquote>
       <figcaption className="flex items-center space-x-3 mt-auto">
        <Image src="generic/Female 4.svg" className="w-16 h-16 rounded-full" />
        <div className="space-y-0.5 font-medium dark:text-white">
         <div>Amanda</div>
         <div>25</div>
        </div>
       </figcaption>
      </figure>
     </div>
    </div>
   </section>
   <UTMCampaign />
   <div id="how it works" className="flex flex-col">
    <div className="flex flex-col md:flex-row gap-14 max-w-screen-xl mx-auto px-4 py-8">
     <div className="object-scale-down h-1/2 flex basis-1/2 order-last md:order-first">
      <ImageGrid
       urls={[
        'sample-proposal/sample-proposal-1-4x5.jpg',
        'sample-proposal/sample-proposal-2-4x5.jpg',
        'sample-proposal/sample-proposal-3-4x5.jpg',
        'sample-proposal/sample-proposal-4-4x5.jpg',
        'sample-proposal/sample-proposal-5-4x5.jpg',
       ]}
       maxHeight="h-1/2"
       orientation="horizontal"
      />
     </div>
     <div className="order-2 flex flex-col justify-center basis-1/2 gap-8">
      <h2 className="primary-h2 mb-0">How it works</h2>
      <div className="gap-2 py-2">
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          Step 1: Complete questionnaire
         </h3>
         <p className="text-gray-500">
          Answer a few simple questions about your current insurance situation
          and potetial future needs in order for the advisors to properly
          provide you with a tailored proposal.
         </p>
        </div>
       </div>
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          Step 2: Get 5 assigned advisors
         </h3>
         <p className="text-gray-500">
          We will ensure that you will get 5 diverse set of prospectives and
          hear from at least one independent advisor. These advisors will not
          get your email until after they submit their suggestions to you.
         </p>
        </div>
       </div>
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          Step 3: Help answer any futher questions
         </h3>
         <p className="text-gray-500">
          Chances are we forgot to ask you something that the advisors need to
          know in order to properly advise you. They will message you through
          our platform so help them help you.
         </p>
        </div>
       </div>
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          Step 4: Pick the advisors that you resonate with
         </h3>
         <p className="text-gray-500">
          After seeing the suggestions and having some brief interactions, it's
          your turn to decide who deserves your time for a full insurance
          analysis. Get one entry to Premiums On Us for each advisor you talk to
          (maximum 3 entries).
         </p>
        </div>
       </div>
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          [Optional] Step 5: Buy and qualify
         </h3>
         <p className="text-gray-500">
          If one of the advisors you talk to end up being the one you feel
          comfortable working with, let us know what you purchased and you're
          now part of our perpetual Premiums On Us giveaway.
         </p>
         <p>
          if none of them feel like a good fit, let us know and we can share
          other options with you.
         </p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   <div id="CTA" className="bg-green-2">
    <div className="flex flex-col max-w-screen-2xl mx-auto px-4 py-8 gap-4">
     <GetStarted />
    </div>
   </div>
   <div id="FAQ" className="bg-white-1">
    <div className="flex flex-col max-w-screen-2xl mx-auto px-4 py-8 gap-4">
     <h2 className="primary-h3 text-center text-green-1">
      Frequently asked questions
     </h2>
     <div className="w-full mx-auto max-w-2xl *:*:*:*:*:py-1 ">
      <Accordion type="single" collapsible>
       <AccordionItem value="free">
        <AccordionTrigger className="text-start w-full">
         Is it really free?
        </AccordionTrigger>
        <AccordionContent>
         <p>Yes it is!</p>

         <p>
          Our priority is to provide you with valuable and personalised advice
          without stress on your end. This unique arrangement allows us to bring
          together serious experts who genuinely want to help you make informed
          decisions about your insurance options.
         </p>

         <p>
          Your access to expert opinions and guidance comes at no expense to
          you. We believe that everyone deserves access to reliable insurance
          advice, and our free service reflects that commitment.
         </p>
        </AccordionContent>
       </AccordionItem>
       <AccordionItem id="premium-on-us" value="premium-on-us">
        <AccordionTrigger className="text-start">
         Tell me more about the Premiums On Us
        </AccordionTrigger>
        <AccordionContent>
         <p>
          Our quarterly insurance giveaway is a golden opportunity to get your
          policies paid for by simply engaging the right advisors. Here's
          exactly how it works:
         </p>
         <p>
          You can earn entries by signing up on our platform and connecting with
          the 5 diverse advisors we've randomly selected. Have an intro call
          with any of those vetted advisor matches? Bingo - that's one entry for
          a chance at premium freedom!{' '}
          <b>
           However, you can only earn up to 3 entries maximum from advisor
           conversations.
          </b>
         </p>
         <p>
          But listen closely: Those entries you're racking up carry some serious
          weight. They never expire, rolling over infinitely from quarter to
          quarter until you finally hit the jackpot. That means more calls =
          more entries = more bites at the no-premium apple.
         </p>
         <p>
          Now for the fine print on what qualifies for the premium prize if you
          win: Any initial policies purchased by working with advisors from your
          customized match list.
         </p>
         <p>
          Here's an example: Let's say you match with advisors A, B, C, D and E.
          You chat with A, B, and C, earning 3 entries for the quarter. Then you
          buy a policy through advisor B.{' '}
          <b>You now have 3 entries to Premiums On Us</b>
         </p>
         <p>
          But let's say you purchased something from advisor F who wasn't even
          part of your recommended list.{' '}
          <b>
           That policy wouldn't count towards the giveaway prize since you
           didn't first engage an advisor match.
          </b>
         </p>
         <p>
          Make sense? Follow this simple formula - sign up, connect with up to 3
          advisors, buy your policies through them. The more you engage the
          advisors we've curated just for you, the more chances you give
          yourself to pay nothing for an entire month of insurance. Powerful
          stuff.
         </p>
         <p>
          Don't miss this opportunity to get quality coverage at a quarter of
          the cost. Sign up now and start banking those entries with advisor
          conversations. <b>That $0 premium could be just a few chats away!</b>
         </p>
         <PremiumsOnUsFAQ triggerText="Click here for more information" />
        </AccordionContent>
       </AccordionItem>
       <AccordionItem value="workwith">
        <AccordionTrigger className="text-start">
         Do I have to work with one of the insurance experts?
        </AccordionTrigger>
        <AccordionContent>
         <p>
          Not at all! We understand that finding the right fit is essential,
          which is why we provide you with up to 5 personalised insurance
          options from a diverse range of experts. Our goal is to ensure you
          have the freedom to choose what works best for you.
         </p>

         <p>
          The experts we connect you with come from various reputable companies,
          including at least one independent insurance expert - ensuring you
          receive a well-rounded selection.
         </p>

         <p>
          We hope that the suggestions provided by our experts meet your needs
          and preferences. However, if none of them are tailored to your liking,
          there's no requirement or obligation to work with any of the experts
          recommended.
         </p>
        </AccordionContent>
       </AccordionItem>
       <AccordionItem value="foreigner">
        <AccordionTrigger className="text-start">
         I'm a foreigner, can I still get the insurance proposals?
        </AccordionTrigger>
        <AccordionContent>
         <p>
          If you've a valid work pass in Singapore, you are eligible to apply
          and receive personalised insurance proposals. But if you don't
          currently hold a work pass, there are still options available for you.
          Message us on Telegram for more details. ☺️
         </p>
        </AccordionContent>
       </AccordionItem>
       <AccordionItem value="whoareyou">
        <AccordionTrigger className="text-start">
         Who is Whatins?
        </AccordionTrigger>
        <AccordionContent>
         <p>
          We run one of the most well known personal finance / adulting brands
          in Singapore called sav.Finance. Lately, we've been receiving an
          influx of direct messages from our followers and readers, all seeking
          guidance on insurance matters. That's why we created this website - to
          provide you with the necessary support and assistance in navigating
          the complex world of insurance options.
         </p>
        </AccordionContent>
       </AccordionItem>
      </Accordion>
     </div>
    </div>
   </div>
  </div>
 );
};

export const InsuranceGuideLandingPage = () => {
 return (
  <div className="bg-white-1">
   <Helmet>
    <title>Your (Actual) Insurance Guide</title>
    <meta
     name="description"
     content="Learn how to find honest agents and avoid spending $1000s on products that aren't for you. Get personalized insurance advice from 5 experts for free."
    />
    <meta property="og:title" content="Your (Actual) Insurance Guide" />
    <meta
     property="og:description"
     content="Learn how to find honest agents and avoid spending $1000s on products that aren't for you. Get personalized insurance advice from 5 experts for free."
    />
    <meta property="og:image" content="/images/insurance-guide-cover.png" />
    <meta property="og:url" content="https://www.yourinsuranceguide.com" />
   </Helmet>

   <div className="grid py-8 px-4 mx-auto max-w-screen-xl lg:gap-12 xl:gap-0 lg:pt-16 lg:grid-cols-12">
    <div className="place-self-center mr-auto mb-10 lg:col-span-7 xl:col-span-7 xl:mb-0">
     <h1 className="primary-h1 mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
      Your (Actual) Insurance Guide
     </h1>
     <p className="mb-6 max-w-2xl font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
      Learn how to find honest agents and avoid spending $1000s on products that
      aren't for you. Get personalized advice from 5 insurance experts for free.
     </p>
     <GetStartedFormContent
      sidebarLayout={false}
      randomVariation="guide_landing"
      cta="Get Your Free Guide →"
      apiEndpoint="/api/insurance-guide-signup"
      successToastMessage="Your insurance guide has been sent to your email!"
      errorToastMessage="There was a problem sending your guide. Please try again."
      posthogEventName="insurance_guide_requested"
      dataLayerEventName="insurance_guide_signup"
      navigationPath={null}
      showGoogleSignIn={false}
     />
    </div>
    <div className="justify-center p-4 max-w-screen-s lg:mt-0 lg:col-span-5 xl:col-span-5 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
     <Image
      src="ad_sav_insurance_guide.png"
      alt="Insurance Guide Cover"
      imgClass="w-full rounded-2xl"
     />
    </div>
   </div>

   <div id="whats-covered" className="flex flex-col bg-green-2">
    <div className="flex flex-col md:flex-row gap-14 max-w-screen-xl mx-auto px-4 py-8">
     <div className="object-scale-down h-1/2 flex basis-1/2 order-last md:order-first">
      <ImageGrid
       urls={[
        'lp-ins-guide/insurance_guide_sample_2.png',
        'lp-ins-guide/insurance_guide_sample_1.png',
        'lp-ins-guide/insurance_guide_sample_3.png',
       ]}
       maxHeight="h-1/2"
       orientation="horizontal"
      />
     </div>
     <div className="order-2 flex flex-col justify-center basis-1/2 gap-8">
      <h2 className="primary-h2 mb-0">What's Covered</h2>
      <div className="gap-2 py-2">
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          Types of Insurance
         </h3>
         <p className="text-gray-500">
          Learn about the 5 main types of life insurance and which ones are
          right for your situation.
         </p>
        </div>
       </div>
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          Financial Planning Worksheets
         </h3>
         <p className="text-gray-500">
          Use our worksheets to calculate your net worth and understand your
          future financial needs.
         </p>
        </div>
       </div>
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          Choosing the Right Advisor
         </h3>
         <p className="text-gray-500">
          Discover key questions to ask when selecting a financial advisor to
          ensure they're the right fit for you.
         </p>
        </div>
       </div>
       <div className="flex flex-row gap-1 md:gap-6 py-2">
        <Image src="check-circle.svg" className="w-8 h-8 flex-none pt-2" />
        <div className="flex flex-col">
         <h3 className="text-h5 text-green-1 font-semibold">
          Common Pitfalls to Avoid
         </h3>
         <p className="text-gray-500">
          Learn about common insurance mistakes and how to avoid overspending on
          unnecessary products.
         </p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export { AdvertisingLP };
