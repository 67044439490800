import React, { useState, useEffect } from 'react';
import { useToast } from '../components/ui/use-toast';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '../components/ui/checkbox';
import {
 Table,
 TableBody,
 TableCell,
 TableHead,
 TableHeader,
 TableRow,
} from '../components/ui/table';
import {
 Accordion,
 AccordionItem,
 AccordionTrigger,
 AccordionContent,
} from '../components/ui/accordion';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

const DeleteComponent = () => {
 const [id, setId] = useState('');
 const { toast } = useToast();

 const handleDelete = async () => {
  try {
   const response = await fetch(`/api/delete-fake`, {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
   });
   if (response.status === 200) {
    toast({
     title: `${id} was successfully deleted`,
    });
   } else if (response.status === 400) {
    const error = await response.json(); // Assuming the error message is in the response body
    toast({
     title: `Error deleting`,
     description: error.message, // Assuming error message is provided by your API
    });
   }
  } catch (error) {
   toast({
    title: `Error deleting`,
    description: error.message,
   });
  }
 };

 // Modif

 return (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2">
   <h1 className="text-xl font-bold">Manually delete prospect</h1>
   <p>
    This is for all the fake prospects we create. It will delete the prospect
    from airtable, firebase and convertkit
   </p>
   <input
    type="text"
    className="px-2"
    value={id}
    onChange={e => setId(e.target.value)}
    placeholder="Enter ID to delete"
   />
   <button
    className="bg-purple-500 p-2 rounded-2xl text-white"
    onClick={handleDelete}
   >
    Delete
   </button>
  </div>
 );
};

const AddComponent = () => {
 const [prospects, setProspects] = useState([]);
 const [advisors, setAdvisors] = useState([]);
 const [agencies, setAgencies] = useState([]);
 const [selectedProspectID, setSelectedProspectID] = useState('');
 const [selectedAdvisors, setSelectedAdvisors] = useState([]);
 const [selectedAgencies, setSelectedAgencies] = useState([]);
 const { toast } = useToast();
 const [isLoading, setIsLoading] = useState(false);

 useEffect(() => {
  fetchProspectsAndAdvisors();
 }, []);

 const fetchProspectsAndAdvisors = async () => {
  try {
   const response = await fetch('/api/get-fully-qualified');
   const data = await response.json();
   setProspects(data.prospects);
   setAdvisors(data.advisors);
   setAgencies(data.agencies);
   setSelectedAgencies(data.agencies.map(agency => ({ id: agency.id })));
   console.log(data.agencies);
  } catch (error) {
   console.error('Error fetching data:', error);
  }
 };

 const handleAdvisorSelection = (advisorId, isChecked, isFree) => {
  if (isChecked) {
   setSelectedAdvisors([...selectedAdvisors, { id: advisorId, isFree }]);
  } else {
   setSelectedAdvisors(
    selectedAdvisors.filter(advisor => advisor.id !== advisorId)
   );
  }
 };

 const handleAgencySelection = (agencyId, isChecked) => {
  if (isChecked) {
   setSelectedAgencies([...selectedAgencies, { id: agencyId }]);
  } else {
   setSelectedAgencies(
    selectedAgencies.filter(agency => agency.id !== agencyId)
   );
  }
 };

 const handleAssign = async () => {
  setIsLoading(true);
  try {
   const response = await fetch('/api/manual-add', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     prospectID: selectedProspectID,
     advisorIDs: selectedAdvisors,
     agencyIDs: selectedAgencies,
    }),
   });
   if (response.ok) {
    toast({
     title: `Successfully assigned advisors and agencies to prospect ${selectedProspectID}`,
    });
    setSelectedProspectID('');
    setSelectedAdvisors([]);
    setSelectedAgencies([]);
   } else {
    const error = await response.json();
    toast({
     title: `Error assigning advisors and agencies`,
     description: error.message,
    });
   }
  } catch (error) {
   toast({
    title: `Error assigning advisors and agencies`,
    description: error.message,
   });
  }
  setIsLoading(false);
 };

 const handleProspectSelection = (id: string) => {
  setSelectedProspectID(id);
 };

 const selectedProspect = prospects.find(p => p.id === selectedProspectID);

 return (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4">
   <h1 className="text-xl font-bold">Assign Advisors to Prospect</h1>

   <Accordion type="single" collapsible className="w-full">
    <AccordionItem value="prospects">
     <AccordionTrigger>Prospects</AccordionTrigger>
     <AccordionContent>
      {prospects.map(prospect => (
       <div key={prospect.id} className="flex items-center space-x-2">
        <input
         type="radio"
         id={prospect.id}
         name="prospect"
         value={prospect.id}
         checked={selectedProspectID === prospect.id}
         onChange={() => handleProspectSelection(prospect.id)}
        />
        <label htmlFor={prospect.id}>
         {prospect.name || 'Unnamed'} ({prospect.id}) -{' '}
         {new Date(prospect.created_date).toLocaleDateString()}
        </label>
       </div>
      ))}
     </AccordionContent>
    </AccordionItem>
   </Accordion>
   <div className="grid grid-cols-5">
    <div className="col-span-2 border-r border-gray-200 px-2">
     {selectedProspect && (
      <div className="prospect-details">
       <h2
        className={`font-bold text-xl capitalize primary-h3 ${
         selectedProspect.number ? 'text-green-1' : 'text-yellow-200'
        }`}
       >
        {' '}
        Prospect Profile
       </h2>
       <ul className="space-y-4">
        <ProfileRow label="Name" value={selectedProspect.name} />
        <ProfileRow label="Email" value={selectedProspect.email || 'N/A'} />
        <ProfileRow
         label="Gender"
         value={formatGender(selectedProspect.gender)}
        />
        <ProfileRow
         label="Birthday"
         value={formatDate(
          `${selectedProspect.year}-${selectedProspect.month}-${selectedProspect.day}`
         )}
        />
        <ProfileRow
         label="Singaporean"
         value={formatYesNo(selectedProspect.singaporean)}
        />
        <ProfileRow label="Occupation" value={selectedProspect.occupation} />
        <ProfileRow
         label="Monthly Income"
         value={formatCurrency(selectedProspect.income)}
        />
        <ProfileRow
         label="Relationship Status"
         value={formatRelationshipStatus(selectedProspect.relationship)}
        />
        <ProfileRow
         label="Current Kids"
         value={formatChildren(selectedProspect.currentKids)}
        />
        <ProfileRow
         label="More Kids In Future"
         value={selectedProspect.moreKids || 'Not specified'}
        />
        <ProfileRow
         label="Other Dependants"
         value={selectedProspect.dependants || 'None'}
        />
        <ProfileRow
         label="Smoker?"
         value={formatYesNo(selectedProspect.smoker)}
        />
        <ProfileRow
         label="Medical Condition"
         value={selectedProspect.explain || 'None'}
        />
        <ProfileRow
         label="Existing Policies"
         value={selectedProspect.existing_policies || 'None'}
        />
        <ProfileRow
         label="Insurance Types"
         value={formatInsuranceTypes(selectedProspect.insuranceTypes)}
        />
        <ProfileRow
         label="Budget"
         value={formatCurrency(selectedProspect.budget)}
        />
        <ProfileRow
         label="Ideal Property Budget"
         value={selectedProspect.property || 'Not specified'}
        />
        <ProfileRow
         label="Other Aspirations"
         value={selectedProspect.aspirations || 'None'}
        />
        <ProfileRow
         label="Phone Number"
         value={formatPhoneNumber(selectedProspect.number)}
        />
        <ProfileRow label="Source" value={selectedProspect.source} />
       </ul>

       {/* Add confirmation button */}
       <button
        onClick={() => {
         if (
          window.confirm('Are you sure you want to mark this prospect as fake?')
         ) {
          fetch('/api/confirm-fake', {
           method: 'POST',
           headers: {
            'Content-Type': 'application/json',
           },
           body: JSON.stringify({ userID: selectedProspect.id }),
          })
           .then(response => {
            if (response.ok) {
             toast({
              title: 'Success',
              description: 'Prospect marked as fake',
             });
            } else {
             throw new Error('Failed to mark prospect as fake');
            }
           })
           .catch(error => {
            toast({
             title: 'Error',
             description: error.message,
             variant: 'destructive',
            });
           });
         }
        }}
        className="mt-4 w-full bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition-colors"
       >
        Mark as Fake Prospect
       </button>
      </div>
     )}
    </div>
    <div className="col-span-3">
     <Table>
      <TableHeader>
       <TableRow>
        <TableHead>Name</TableHead>
        <TableHead>Company Name</TableHead>
        <TableHead>Telegram</TableHead>
        <TableHead>Agency</TableHead>
        <TableHead>Last Prospect</TableHead>
        <TableHead>Total Leads Owed</TableHead>
        <TableHead>Assign</TableHead>
        <TableHead>Free</TableHead>
       </TableRow>
      </TableHeader>
      <TableBody>
       {agencies.map(agency => (
        <TableRow key={agency.id}>
         <TableCell>{agency.fields.Name}</TableCell>
         <TableCell></TableCell>
         <TableCell></TableCell>
         <TableCell>
          <Checkbox
           defaultChecked={true}
           checked={selectedAgencies.some(a => a.id === agency.id)}
           onCheckedChange={checked =>
            handleAgencySelection(agency.id, checked)
           }
          />
         </TableCell>
         <TableCell></TableCell>
         <TableCell>{agency.fields['Total Leads Owed']}</TableCell>
         <TableCell>
          <Checkbox
           defaultChecked={true}
           checked={selectedAgencies.some(a => a.id === agency.id)}
           onCheckedChange={checked =>
            handleAgencySelection(agency.id, checked)
           }
          />
         </TableCell>
         <TableCell></TableCell>
        </TableRow>
       ))}
       {advisors.map(advisor => (
        <TableRow key={advisor.id}>
         <TableCell>{advisor.fields.Name}</TableCell>
         <TableCell>{advisor.fields['Company Name']}</TableCell>
         <TableCell>{advisor.fields['Telegram ID']}</TableCell>
         <TableCell></TableCell>
         <TableCell>
          {advisor.fields['Last Prospect']
           ? new Date(advisor.fields['Last Prospect']).toLocaleDateString(
              'en-GB'
             )
           : 'N/A'}
         </TableCell>
         <TableCell>{advisor.fields['Total Leads Owed']}</TableCell>
         <TableCell>
          <Checkbox
           checked={selectedAdvisors.some(a => a.id === advisor.id)}
           onCheckedChange={checked =>
            handleAdvisorSelection(advisor.id, checked, false)
           }
          />
         </TableCell>
         <TableCell>
          <Checkbox
           checked={selectedAdvisors.some(a => a.id === advisor.id && a.isFree)}
           onCheckedChange={checked =>
            handleAdvisorSelection(advisor.id, checked, true)
           }
          />
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>

     <button
      onClick={handleAssign}
      disabled={
       !selectedProspectID ||
       (selectedAdvisors.length === 0 && selectedAgencies.length === 0) ||
       isLoading
      }
      className="primary-button2 w-full"
     >
      {isLoading ? 'Assigning...' : 'Assign'}
     </button>
    </div>
   </div>
  </div>
 );
};

const AssignAdvisorsComponent = () => {
 const [prospectID, setProspectID] = useState('');
 const [slots, setSlots] = useState('');
 const [releaseDate, setReleaseDate] = useState('');
 const [isLoading, setIsLoading] = useState(false);
 const { toast } = useToast();

 const formatDate = dateString => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
 };

 const handleAutoAssign = async () => {
  setIsLoading(true);
  try {
   const formattedReleaseDate = formatDate(releaseDate);
   const response = await fetch(`../api/assign-advisors`, {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     prospectID,
     member_slots: slots ? parseInt(slots) : undefined,
     releaseDate: formattedReleaseDate,
    }),
   });

   if (response.ok) {
    const data = await response.json();
    toast({
     title: `Successfully assigned advisors to prospect ${prospectID}`,
     description: `${data.exclusiveWinners.length} advisors assigned. Release date: ${formattedReleaseDate}`,
    });
   } else {
    const error = await response.json();
    toast({
     title: `Error assigning advisors`,
     description: error.message,
     variant: 'destructive',
    });
   }
  } catch (error) {
   toast({
    title: `Error assigning advisors`,
    description: error.message,
    variant: 'destructive',
   });
  }
  setIsLoading(false);
 };

 return isLoading ? (
  <div>Loading...</div>
 ) : (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2">
   <h1 className="text-xl font-bold">Assign Advisors to Prospect</h1>
   <p>This will assign advisors to the specified prospect</p>
   <input
    type="text"
    className="px-2 py-1 rounded"
    value={prospectID}
    onChange={e => setProspectID(e.target.value)}
    placeholder="Prospect ID"
   />
   <input
    type="number"
    className="px-2 py-1 rounded"
    value={slots}
    onChange={e => setSlots(e.target.value)}
    placeholder="Number of slots (optional)"
   />
   <input
    type="date"
    className="px-2 py-1 rounded"
    value={releaseDate}
    onChange={e => setReleaseDate(e.target.value)}
    placeholder="Release Date"
   />
   <button
    className="bg-purple-500 p-2 rounded-2xl text-white hover:bg-purple-600 transition-colors"
    onClick={handleAutoAssign}
   >
    Assign Advisors
   </button>
  </div>
 );
};

const ViewAsAdvisorComponent = () => {
 const [advisorID, setAdvisorID] = useState('');
 const { toast } = useToast();
 const [isLoading, setIsLoading] = useState(false);

 const handleViewAsAdvisor = async () => {
  setIsLoading(true);
  try {
   const response = await fetch('/api/view-as-advisor', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({ advisorID }),
   });

   if (response.ok) {
    toast({
     title: `Successfully changed to view as advisor ${advisorID}`,
    });
    setAdvisorID('');
   } else {
    const error = await response.json();
    toast({
     title: `Error changing advisor view`,
     description: error.message,
     variant: 'destructive',
    });
   }
  } catch (error) {
   toast({
    title: `Error changing advisor view`,
    description: error.message,
    variant: 'destructive',
   });
  }
  setIsLoading(false);
 };

 return isLoading ? (
  <div>Loading...</div>
 ) : (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2">
   <h1 className="text-xl font-bold">View as Advisor</h1>
   <p>Enter an Advisor ID to view the application as that advisor</p>
   <input
    type="text"
    className="px-2 py-1 rounded"
    value={advisorID}
    onChange={e => setAdvisorID(e.target.value)}
    placeholder="Advisor ID"
   />
   <button
    className="bg-purple-500 p-2 rounded-2xl text-white hover:bg-purple-600 transition-colors"
    onClick={handleViewAsAdvisor}
   >
    View as Advisor
   </button>
  </div>
 );
};

const RemoveFromChatComponent = () => {
 const [recordId, setRecordId] = useState('');
 const { toast } = useToast();

 const handleRemove = async () => {
  try {
   const response = await fetch(`/api/remove-from-chat`, {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({ recordId }),
   });

   if (response.ok) {
    const data = await response.json();
    toast({
     title: 'Successfully removed from chats',
     description: `Removed from ${data.count} chats`,
    });
    setRecordId(''); // Clear input after success
   } else {
    const error = await response.json();
    toast({
     title: 'Error removing from chats',
     description: error.message,
     variant: 'destructive',
    });
   }
  } catch (error) {
   toast({
    title: 'Error removing from chats',
    description: error.message,
    variant: 'destructive',
   });
  }
 };

 return (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2">
   <h1 className="text-xl font-bold">Remove from Chat</h1>
   <p>Remove a prospect from all telegram chats</p>
   <input
    type="text"
    className="px-2 py-1 rounded"
    value={recordId}
    onChange={e => setRecordId(e.target.value)}
    placeholder="Record ID"
   />
   <button
    className="bg-purple-500 p-2 rounded-2xl text-white hover:bg-purple-600 transition-colors"
    onClick={handleRemove}
   >
    Remove from Chats
   </button>
  </div>
 );
};

const TelegramUsersComponent = () => {
 const [users, setUsers] = useState<any[]>([]);
 const [isLoading, setIsLoading] = useState(false);
 const [selectedCommands, setSelectedCommands] = useState<{
  [key: string]: string;
 }>({});
 const { toast } = useToast();

 // Available telegram commands
 const telegramCommands = [
  { value: 'begin_questionnaire', label: '1. Begin Questionnaire' },
  { value: 'finish_questionnaire', label: '2. Finish Questionnaire' },
  { value: 'confirm_prospect', label: '3. Confirm Prospect' },
  { value: 'assign_room', label: '4. Assign Room' },
  { value: 'still_interested', label: '5. Check if Still Interested' },
  { value: 'closing_reminder', label: '6. Closing Reminder' },
  { value: 'delete_room', label: '7. Delete Room' },
 ];

 useEffect(() => {
  fetchTelegramUsers();
 }, []);

 const fetchTelegramUsers = async () => {
  setIsLoading(true);
  try {
   const response = await fetch('/api/telegram-users');
   const data = await response.json();
   if (data.status === 'success') {
    // Filter out users with "not_interested" in their actions
    const filteredUsers = (data.users || []).filter(user => {
     if (!user.actions || user.actions.length === 0) return true;

     // Check if any action contains "not_interested"
     return !user.actions.some(
      action => action.type && action.type.includes('not_interested')
     );
    });
    setUsers(filteredUsers);
   } else {
    throw new Error(data.message || 'Failed to fetch users');
   }
  } catch (error) {
   toast({
    title: 'Error fetching users',
    description: error.message,
    variant: 'destructive',
   });
  } finally {
   setIsLoading(false);
  }
 };

 const executeCommand = async (telegramId: string, command: string) => {
  try {
   const response = await fetch('/api/telegram-command', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     command,
     record_id: telegramId,
    }),
   });

   if (response.ok) {
    toast({
     title: 'Command executed successfully',
     description: `${command} executed for user ${telegramId}`,
    });
    // Refresh the users list after executing a command
    fetchTelegramUsers();
   } else {
    const error = await response.json();
    throw new Error(error.message);
   }
  } catch (error) {
   toast({
    title: 'Error executing command',
    description: error.message,
    variant: 'destructive',
   });
  }
 };

 return (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4">
   <h1 className="text-xl font-bold">Telegram Users</h1>
   {isLoading ? (
    <div className="text-center py-4">Loading...</div>
   ) : (
    <Table>
     <TableHeader>
      <TableRow>
       <TableHead>Username</TableHead>
       <TableHead>Name</TableHead>
       <TableHead>Telegram ID</TableHead>
       <TableHead>Record ID</TableHead>
       <TableHead>Created At</TableHead>
       <TableHead>Last Action</TableHead>
       <TableHead>Actions History</TableHead>
       <TableHead>Execute Command</TableHead>
      </TableRow>
     </TableHeader>
     <TableBody>
      {users.length === 0 ? (
       <TableRow>
        <TableCell colSpan={8} className="text-center">
         No users found
        </TableCell>
       </TableRow>
      ) : (
       users.map(user => (
        <TableRow key={user.id}>
         <TableCell>{user.username}</TableCell>
         <TableCell>{`${user.first_name || ''} ${
          user.last_name || ''
         }`}</TableCell>
         <TableCell>{user.telegram_id}</TableCell>
         <TableCell>{user.record_id}</TableCell>
         <TableCell>{new Date(user.created_at).toLocaleDateString()}</TableCell>
         <TableCell>
          {user.last_action ? user.last_action.type : 'No actions'}
         </TableCell>
         <TableCell>
          <Accordion type="single" collapsible>
           <AccordionItem value="actions">
            <AccordionTrigger>View History</AccordionTrigger>
            <AccordionContent>
             {user.actions?.map((action: any, index: number) => (
              <div key={index} className="py-1">
               <span className="font-medium">{action.type}</span>
               <br />
               <span className="text-sm text-gray-500">
                {new Date(action.timestamp).toLocaleString()}
               </span>
              </div>
             )) || 'No action history'}
            </AccordionContent>
           </AccordionItem>
          </Accordion>
         </TableCell>
         <TableCell>
          <div className="flex gap-2">
           <select
            className="rounded border p-1"
            onChange={e =>
             setSelectedCommands({
              ...selectedCommands,
              [user.record_id]: e.target.value,
             })
            }
            value={selectedCommands[user.record_id] || ''}
           >
            <option value="" disabled>
             Select command
            </option>
            {telegramCommands.map(cmd => (
             <option key={cmd.value} value={cmd.value}>
              {cmd.label}
             </option>
            ))}
           </select>
           <button
            className="bg-purple-500 text-white px-3 py-1 rounded hover:bg-purple-600 disabled:bg-gray-300"
            onClick={() =>
             executeCommand(user.record_id, selectedCommands[user.record_id])
            }
            disabled={!selectedCommands[user.record_id]}
           >
            Execute
           </button>
          </div>
         </TableCell>
        </TableRow>
       ))
      )}
     </TableBody>
    </Table>
   )}
  </div>
 );
};

const TelegramChatsComponent = () => {
 const [chats, setChats] = useState<any[]>([]);
 const [isLoading, setIsLoading] = useState(false);
 const [selectedChat, setSelectedChat] = useState<string | null>(null);
 const [sendRating, setSendRating] = useState(true);
 const { toast } = useToast();

 useEffect(() => {
  fetchTelegramChats();
 }, []);

 const fetchTelegramChats = async () => {
  setIsLoading(true);
  try {
   const response = await fetch('/api/get-telegram-chats');
   const data = await response.json();
   if (data.status === 'success') {
    setChats(data.chats || []);
   } else {
    throw new Error(data.message || 'Failed to fetch chats');
   }
  } catch (error) {
   toast({
    title: 'Error fetching chats',
    description: error.message,
    variant: 'destructive',
   });
  } finally {
   setIsLoading(false);
  }
 };

 const closeChat = async (chatId: string) => {
  if (!window.confirm('Are you sure you want to close this chat?')) {
   return;
  }

  try {
   const response = await fetch('/api/close-telegram-chat', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     chatId,
     sendRating,
    }),
   });

   if (response.ok) {
    toast({
     title: 'Chat closed successfully',
     description: sendRating ? 'Rating request sent to user' : undefined,
    });
    // Refresh the chats list after closing a chat
    fetchTelegramChats();
    setSelectedChat(null);
   } else {
    const error = await response.json();
    throw new Error(error.message);
   }
  } catch (error) {
   toast({
    title: 'Error closing chat',
    description: error.message,
    variant: 'destructive',
   });
  }
 };

 const formatDate = dateString => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleString();
 };

 return (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4">
   <div className="flex justify-between items-center">
    <h1 className="text-xl font-bold">Telegram Chats</h1>
    <div className="flex items-center gap-4">
     <div className="flex items-center">
      <Checkbox
       id="send-rating"
       checked={sendRating}
       onCheckedChange={checked => setSendRating(checked as boolean)}
      />
      <label htmlFor="send-rating" className="ml-2 text-sm">
       Send rating request when closing
      </label>
     </div>
     <button
      className="bg-purple-500 p-2 rounded-2xl text-white hover:bg-purple-600 transition-colors"
      onClick={fetchTelegramChats}
     >
      Refresh
     </button>
    </div>
   </div>

   {isLoading ? (
    <div className="text-center py-4">Loading...</div>
   ) : (
    <Table>
     <TableHeader>
      <TableRow>
       <TableHead>Chat ID</TableHead>
       <TableHead>Status</TableHead>
       <TableHead>Prospect</TableHead>
       <TableHead>Advisor</TableHead>
       <TableHead>Created At</TableHead>
       <TableHead>Updated At</TableHead>
       <TableHead>Actions</TableHead>
      </TableRow>
     </TableHeader>
     <TableBody>
      {chats.length === 0 ? (
       <TableRow>
        <TableCell colSpan={7} className="text-center">
         No chats found
        </TableCell>
       </TableRow>
      ) : (
       chats.map(chat => (
        <TableRow
         key={chat.id}
         className={`
           ${selectedChat === chat.id ? 'bg-blue-100' : ''}
           ${
            chat.prospect_id && !chat.advisor_telegram_id ? 'bg-amber-100' : ''
           }
         `}
         onClick={() => setSelectedChat(chat.id)}
        >
         <TableCell>{chat.id}</TableCell>
         <TableCell>
          <span
           className={`px-2 py-1 rounded ${
            chat.prospect_id && !chat.advisor_telegram_id
             ? 'bg-amber-200'
             : chat.status === 'active'
             ? 'bg-green-200'
             : chat.status === 'closed'
             ? 'bg-red-200'
             : 'bg-gray-200'
           }`}
          >
           {chat.prospect_id && !chat.advisor_telegram_id
            ? 'no_show'
            : chat.status}
          </span>
          {chat.prospect_id && !chat.advisor_telegram_id && (
           <span className="ml-2 text-amber-600 text-xs font-medium">
            Waiting for advisor
           </span>
          )}
         </TableCell>
         <TableCell>
          {chat.prospect_name || 'N/A'}
          {chat.prospect_id && (
           <div className="text-xs text-gray-500">{chat.prospect_id}</div>
          )}
          {chat.prospect_telegram_id && (
           <div className="text-xs text-gray-500">
            Telegram: {chat.prospect_telegram_id}
           </div>
          )}
         </TableCell>
         <TableCell>
          {chat.advisor_name || 'N/A'}
          {chat.advisor_id && (
           <div className="text-xs text-gray-500">{chat.advisor_id}</div>
          )}
         </TableCell>
         <TableCell>{formatDate(chat.created_at)}</TableCell>
         <TableCell>{formatDate(chat.updated_at)}</TableCell>
         <TableCell>
          <div className="flex gap-2">
           {chat.status !== 'closed' && (
            <button
             className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
             onClick={e => {
              e.stopPropagation();
              closeChat(chat.id);
             }}
            >
             Close Chat
            </button>
           )}
           {chat.invite_link && (
            <button
             className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
             onClick={e => {
              e.stopPropagation();
              window.open(chat.invite_link, '_blank');
             }}
            >
             View
            </button>
           )}
          </div>
         </TableCell>
        </TableRow>
       ))
      )}
     </TableBody>
    </Table>
   )}
  </div>
 );
};

const App = () => {
 const currentUrl = window.location.href;
 const navigate = useNavigate();

 useEffect(() => {
  if (
   !currentUrl.includes('dashboard.whatins.sg') &&
   !currentUrl.includes('localhost') &&
   !currentUrl.includes('http://127.0.0.1:5000/')
  ) {
   navigate('/advisor/profile');
  }
 }, [currentUrl, navigate]);

 if (
  currentUrl.includes('dashboard.whatins.sg') ||
  currentUrl.includes('localhost') ||
  currentUrl.includes('http://127.0.0.1:5000/')
 ) {
  return (
   <div className="container mx-auto py-8 space-y-6">
    <div className="flex flex-row gap-4 mx-auto">
     <DeleteComponent />
     <AssignAdvisorsComponent />
     <ViewAsAdvisorComponent />
     <RemoveFromChatComponent />
    </div>
    <TelegramUsersComponent />
    <TelegramChatsComponent />
    <AddComponent />
    <AgeDistribution />
   </div>
  );
 }

 return <div className="flex flex-row gap-4 mx-auto max-w-2xl"></div>;
};

const AgeDistribution = () => {
 const [ageData, setAgeData] = useState([]);

 useEffect(() => {
  const fetchData = async () => {
   try {
    const response = await fetch('/api/get-prospect', {
     method: 'POST',
     headers: {
      'Content-Type': 'application/json',
     },
    });
    if (!response.ok) {
     throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log(data);
    setAgeData(data);
   } catch (error) {
    console.error('Error fetching age distribution data:', error);
   }
  };

  fetchData();
 }, []);

 return (
  <div>
   <h2>Age Distribution of Prospects</h2>
   <BarChart width={600} height={300} data={ageData}>
    <XAxis dataKey="age" />
    <YAxis />
    <Tooltip />
    <CartesianGrid stroke="#f5f5f5" />
    <Bar dataKey="count" fill="#8884d8" />
   </BarChart>
  </div>
 );
};

const insuranceTypeTags = [
 {
  aliases: [
   'isp',
   'hospital',
   'Medical Condition',
   'recpdtMoJo1cA8FH8',
   'Medical Insurance',
   'integrated shield plan',
  ],
  humanizedType: 'Hospital',
  color: '#FF4136',
  typeId: 'recpdtMoJo1cA8FH8',
  link: '/life-insurance/best-hospital-(isp)-insurance-review-in-singapore',
 },
 {
  aliases: ['pa', 'personal accident', 'recmDVlhrGH7Pa0b9', 'personal'],
  humanizedType: 'Personal Accident',
  color: '#FF851B',
  typeId: 'recmDVlhrGH7Pa0b9',
  link: '/life-insurance/best-personal-accident-insurance-review-in-singapore',
 },
 {
  aliases: [
   'disability',
   'tpd',
   'total permanent disability',
   'total permanent disability insurance',
  ],
  humanizedType: 'Disability',
  color: '#FFDC00',
  typeId: 'recjs035wuJLCIfoX',
  link: '/life-insurance/best-disability-insurance-review-in-singapore',
 },
 {
  aliases: ['whole', 'whole life', 'recY8Oy3y6KEMQytx', 'whole life insurance'],
  humanizedType: 'Whole Life',
  color: '#2ECC40',
  typeId: 'recY8Oy3y6KEMQytx',
  link: '/life-insurance/best-whole-life-insurance-review-in-singapore',
 },
 {
  aliases: ['term', 'term life', 'recpnPXgTA9KwVmcI', 'term life insurance'],
  humanizedType: 'Term Life',
  color: '#0074D9',
  typeId: 'recpnPXgTA9KwVmcI',
  link: '/life-insurance/best-term-life-insurance-review-in-singapore',
 },
 {
  aliases: [
   'ilp',
   'investment',
   'linked',
   'plan',
   'recldvICdozX8cFCp',
   'investment linked plan',
  ],
  humanizedType: 'Investment Linked Plan',
  color: '#B10DC9',
  typeId: 'recldvICdozX8cFCp',
  link: '/life-insurance/best-investment-linked-plan-(ilp)-review-in-singapore',
 },
 {
  aliases: ['endowment', 'saving', 'rec6MQaW9daZ619Bs'],
  humanizedType: 'Endowment',
  color: '#F012BE',
  typeId: 'rec6MQaW9daZ619Bs',
  link: '/life-insurance/best-endowment-savings-plan-review-in-singapore',
 },
 {
  aliases: [
   'ci',
   'critical illness',
   'recfuZEbbuVfjNTWQ',
   'critical',
   'critical illness insurance',
   'cancer insurance',
  ],
  humanizedType: 'Critical Illness',
  color: '#001f3f',
  typeId: 'recfuZEbbuVfjNTWQ',
  link: '/life-insurance/best-critical-illness-insurance-review-in-singapore',
 },
 {
  aliases: ['maternity', 'maternity insurance', 'recvbonxHiXQecX3N'],
  humanizedType: 'Maternity Insurance',
  color: '#001f3f',
  typeId: 'recvbonxHiXQecX3N',
  link: '/life-insurance/best-maternity-insurance-review-in-singapore',
 },
 {
  aliases: ['unsure', 'help me'],
  humanizedType: `I don't know where to start`,
  color: '#001f3f',
  typeId: 'recoEmTY6rMniWxHD',
  link: '/life-insurance/best-term-life-insurance-review-in-singapore',
 },
];

// Helper functions
const formatGender = (gender: string) =>
 gender === 'genderMale' ? 'Male' : 'Female';
const formatYesNo = (value: string) => (value?.endsWith('Yes') ? 'Yes' : 'No');
const formatDate = (dateString: string) => {
 if (!dateString) return 'Not specified';
 const date = new Date(dateString);
 return isNaN(date.getTime())
  ? 'Invalid date'
  : date.toLocaleDateString('en-GB');
};
const formatRelationshipStatus = (status: string) => {
 const statusMap: { [key: string]: string } = {
  relationshipRelationship: 'In a Relationship',
  relationshipMarried: 'Married',
  // Add other statuses as needed
 };
 return statusMap[status] || status || 'Not specified';
};
const formatChildren = (children: string) =>
 children === '0' ? 'No children' : children || 'Not specified';
const formatCurrency = (amount: string) =>
 amount ? `$${amount}` : 'Not specified';
const formatInsuranceTypes = (types: string[]) => {
 if (!types || types.length === 0) return 'None specified';

 return types
  .map(type => {
   const matchingType = insuranceTypeTags.find(
    tag => tag.typeId === type || tag.aliases.includes(type.toLowerCase())
   );
   return matchingType ? matchingType.humanizedType : type;
  })
  .join(', ');
};
const formatPhoneNumber = (number: string) => number || 'Not provided';

const ProfileRow = ({ label, value }: { label: string; value: string }) => (
 <li>
  <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
   {label}:{' '}
   <span className="text-right mb-2 text-sm font-light dark:text-white">
    {value}
   </span>
  </div>
 </li>
);

export default App;
